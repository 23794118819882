(function($) {
    if ($('.accordions').length > 0 ) {
        $(".accordion-title").on('click', function(e) {
            e.preventDefault();       
            $(this).toggleClass("active");
            $(this).next('.accordion-content').slideToggle('fast');
        });
    }

    if ($('.footer-company-infos__address-title').length > 0 ) {
        $(".footer-company-infos__address-title").on('click', function(e) {
            e.preventDefault();

            if ($(window).width() < 767) {
                $(this).toggleClass("active");
                $(this).next('.footer-company-infos__address-text').slideToggle('fast');
            }
        });
    }    

})(jQuery);